import {
  type AppError,
  InvalidTokenError,
  type ResultWithOauthToken,
} from '@packages/utils';
import {
  type UseInfiniteQueryOptions,
  type UseQueryOptions,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import {parseCookies} from 'nookies';
import type {z} from 'zod';
import {DEFAULT_API_VERSION} from '../../config/apiVersion';
import {defaultClient} from '../../lib/defaultClient';
import {createPathWithQuery} from '../../shared/services/createPathWithQuery';
import {filterExistParams} from '../../shared/services/filterExistParams';
import {
  type GetCommunityCommentResponse,
  GetCommunityCommentResponseSchema,
  type GetCommunityCommentsResponse,
  GetCommunityCommentsResponseSchema,
  type GetCommunityDoubtReasonResponse,
  GetCommunityDoubtReasonResponseSchema,
  GetCommunityTagListResponseScheme,
  type GetCommunityTagListResponseType,
  type GetCommunityTopicListResponse,
  GetCommunityTopicListResponseSchema,
  type GetCommunityTopicResponse,
  GetCommunityTopicResponseScheme,
  type PostAlertCommunityCommentResponse,
  PostAlertCommunityCommentResponseSchema,
  type PostAlertCommunityTopicResponse,
  PostAlertCommunityTopicResponseScheme,
  type PostCommunityCommentResponse,
  PostCommunityCommentResponseSchema,
  type PostCommunityTopicResponse,
  PostCommunityTopicResponseScheme,
  type PostDoubtCommunityCommentResponse,
  PostDoubtCommunityCommentResponseSchema,
  type UpdateFollowingCommunityTopicResponse,
  UpdateFollowingCommunityTopicResponseSchema,
  type UpdateLikedCommunityCommentResponse,
  UpdateLikedCommunityCommentResponseSchema,
  communityTopicsHeadlineSchema,
} from '../entities/community/schema';
import {sendApiRequest} from '../usecases/auth/sendApiRequest';
import {useIsSessionExpired} from '../usecases/auth/useIsSessionExpired';

type GetCommunityTopicsHeadlineResponse = z.infer<
  typeof communityTopicsHeadlineSchema
>;
type ResultGetCommunityTopicsHeadline = ResultWithOauthToken<
  GetCommunityTopicsHeadlineResponse,
  AppError
>;

type GetCommunityTopicsHeadlineArgs = {
  cookies: Partial<Record<string, string>>;
  version?: string;
  config?: UseQueryOptions<ResultGetCommunityTopicsHeadline>;
};

export const getCommunityTopicsHeadline = async ({
  cookies,
  version = DEFAULT_API_VERSION,
}: GetCommunityTopicsHeadlineArgs): Promise<ResultGetCommunityTopicsHeadline> => {
  const request = async (accessToken: string) => {
    defaultClient.setToken(accessToken);
    return defaultClient.get(
      `/${version}/community-topics/headline`,
      communityTopicsHeadlineSchema,
    );
  };

  const result = await sendApiRequest({
    cookies,
    authRequired: true,
    request,
    version,
  });

  return result;
};

type UseGetCommunityTopicHeadlineParams = Omit<
  GetCommunityTopicsHeadlineArgs,
  'cookies'
>;

export const useGetCommunityTopicsHeadline = ({
  version = DEFAULT_API_VERSION,
  config,
}: UseGetCommunityTopicHeadlineParams) => {
  const {updateIsSessionExpired} = useIsSessionExpired();

  return useQuery<ResultGetCommunityTopicsHeadline>({
    ...config,
    queryKey: ['getCommunityTopicsHeadline'],
    async queryFn() {
      const cookies = parseCookies();
      const result = await getCommunityTopicsHeadline({cookies, version});
      if (!result.ok && result.error === InvalidTokenError) {
        updateIsSessionExpired(true);
      }

      return result;
    },
  });
};

type GetCommunityTopicListArgs = {
  cookies: Partial<Record<string, string>>;
  version?: string;
  cursor?: string; // Paginationのカーソル。トピックの最終投稿時刻
  limit?: number; // 取得件数
  backward?: boolean; // カーソルを基準に古い方向に取ってゆくか
  desc?: boolean; // 降順にするか
  followed?: boolean; // フォローしているもののみ返すか
  tagID?: number; // タグID
  keyword?: string; // キーワード検索
  recruitmentIDs?: number[]; // 募集詳細ID(複数指定化), 募集詳細に関連するトピックを返す
};

export const getCommunityTopicList = async ({
  cookies,
  version = DEFAULT_API_VERSION,
  cursor,
  limit,
  backward,
  desc,
  followed,
  tagID,
  keyword,
  recruitmentIDs,
}: GetCommunityTopicListArgs): Promise<
  ResultWithOauthToken<GetCommunityTopicListResponse, AppError>
> => {
  const request = async (accessToken: string) => {
    defaultClient.setToken(accessToken);
    const params = filterExistParams({
      cursor,
      limit,
      backward,
      desc,
      followed,
      tagID,
      keyword,
      recruitmentIDs,
    });

    const path = createPathWithQuery(
      `/${version}/community-topics`,
      params,
      'none',
    );
    return defaultClient.get(path, GetCommunityTopicListResponseSchema);
  };

  const result = await sendApiRequest({
    cookies,
    authRequired: false,
    request,
    version,
  });

  return result;
};

type UseGetCommunityTopicListParams = Omit<
  GetCommunityTopicListArgs,
  'cookies'
> & {
  config?: UseQueryOptions<
    ResultWithOauthToken<GetCommunityTopicListResponse, AppError>
  >;
};

export const useGetCommunityTopicList = ({
  version = DEFAULT_API_VERSION,
  cursor,
  limit,
  backward,
  desc,
  followed,
  tagID,
  keyword,
  recruitmentIDs,
  config,
}: UseGetCommunityTopicListParams) => {
  const {updateIsSessionExpired} = useIsSessionExpired();

  return useQuery<
    ResultWithOauthToken<GetCommunityTopicListResponse, AppError>
  >({
    ...config,
    queryKey: [
      'getCommunityTopicList',
      cursor,
      limit,
      backward,
      desc,
      followed,
      tagID,
      keyword,
      recruitmentIDs,
    ],
    async queryFn() {
      const cookies = parseCookies();
      const result = await getCommunityTopicList({
        cookies,
        version,
        cursor,
        limit,
        backward,
        desc,
        followed,
        tagID,
        keyword,
        recruitmentIDs,
      });
      if (!result.ok && result.error === InvalidTokenError) {
        updateIsSessionExpired(true);
      }

      return result;
    },
  });
};

type UseInfiniteGetCommunityTopicListParams = Omit<
  GetCommunityTopicListArgs,
  'cookies'
> & {
  config?: UseInfiniteQueryOptions<
    ResultWithOauthToken<GetCommunityTopicListResponse, AppError>
  >;
};

export const useInfiniteGetCommunityTopicList = ({
  version = DEFAULT_API_VERSION,
  cursor,
  limit,
  backward,
  desc,
  followed,
  tagID,
  keyword,
  recruitmentIDs,
  config,
}: UseInfiniteGetCommunityTopicListParams) => {
  const {updateIsSessionExpired} = useIsSessionExpired();

  return useInfiniteQuery({
    queryKey: [
      'getInfiniteCommunityTopicList',
      cursor,
      limit,
      backward,
      desc,
      followed,
      tagID,
      keyword,
      recruitmentIDs,
    ],
    async queryFn({pageParam = undefined}) {
      const cookies = parseCookies();
      const result = await getCommunityTopicList({
        cookies,
        version,
        cursor: pageParam,
        limit,
        backward,
        desc,
        followed,
        tagID,
        keyword,
        recruitmentIDs,
      });
      if (!result.ok && result.error === InvalidTokenError) {
        updateIsSessionExpired(true);
      }

      return result;
    },
    ...config,
    getNextPageParam: (lastPage) =>
      lastPage.ok ? lastPage.value.previousCursor : undefined, // NextPageParamだが過去方向へのページングなのでpreviousCursorを返す
  });
};

type PostCommunityTopicArgs = {
  cookies: Partial<Record<string, string>>;
  version?: string;
  nickname: string;
  title: string;
  body: string;
  tags?: number[];
  hashtags?: string[];
};

export const postCommunityTopic = async ({
  cookies,
  version = DEFAULT_API_VERSION,
  nickname,
  title,
  body,
  tags,
  hashtags,
}: PostCommunityTopicArgs): Promise<
  ResultWithOauthToken<PostCommunityTopicResponse, AppError>
> => {
  const request = async (accessToken: string) => {
    defaultClient.setToken(accessToken);
    const params = filterExistParams({
      nickname,
      title,
      body,
      tags,
      hashtags,
    });

    return defaultClient.post(
      `/${version}/community-topics`,
      PostCommunityTopicResponseScheme,
      params,
    );
  };

  const result = await sendApiRequest({
    cookies,
    authRequired: true,
    request,
    version,
  });

  return result;
};

type UsePostCommunityTopicParams = {
  version?: string;
  onMutateFnc?: () => void;
  onSuccessFnc: () => void;
  onErrorFnc: (error: AppError) => void;
  onSettledFnc?: () => void;
};

export const usePostCommunityTopic = ({
  version = DEFAULT_API_VERSION,
  onMutateFnc,
  onSuccessFnc,
  onErrorFnc,
  onSettledFnc,
}: UsePostCommunityTopicParams) => {
  const {updateIsSessionExpired} = useIsSessionExpired();

  return useMutation({
    async mutationFn({
      nickname,
      title,
      body,
      tags,
      hashtags,
    }: Omit<PostCommunityTopicArgs, 'cookies'>) {
      const cookies = parseCookies();
      const result = await postCommunityTopic({
        cookies,
        version,
        nickname,
        title,
        body,
        tags,
        hashtags,
      });
      if (!result.ok && result.error === InvalidTokenError) {
        updateIsSessionExpired(true);
      }

      return result;
    },
    onMutate() {
      if (onMutateFnc) {
        onMutateFnc();
      }
    },
    onSuccess() {
      onSuccessFnc();
    },
    onError(error: AppError) {
      onErrorFnc(error);
    },
    onSettled() {
      if (onSettledFnc) {
        onSettledFnc();
      }
    },
  });
};

type GetCommunityTopicArgs = {
  cookies: Partial<Record<string, string>>;
  version?: string;
  topicId: number;
};

export const getCommunityTopic = async ({
  cookies,
  version = DEFAULT_API_VERSION,
  topicId,
}: GetCommunityTopicArgs): Promise<
  ResultWithOauthToken<GetCommunityTopicResponse, AppError>
> => {
  const request = async (accessToken: string) => {
    defaultClient.setToken(accessToken);
    return defaultClient.get(
      `/${version}/community-topics/${topicId}`,
      GetCommunityTopicResponseScheme,
    );
  };

  const result = await sendApiRequest({
    cookies,
    authRequired: true,
    request,
    version,
    allowReturnError: true,
  });

  return result;
};

type UseGetCommunityTopicParams = Omit<GetCommunityTopicArgs, 'cookies'> & {
  config?: UseQueryOptions<
    ResultWithOauthToken<GetCommunityTopicResponse, AppError>
  >;
};

export const useGetCommunityTopic = ({
  version = DEFAULT_API_VERSION,
  topicId,
  config,
}: UseGetCommunityTopicParams) => {
  const {updateIsSessionExpired} = useIsSessionExpired();

  return useQuery<ResultWithOauthToken<GetCommunityTopicResponse, AppError>>({
    ...config,
    queryKey: ['getCommunityTopic', topicId],
    async queryFn() {
      const cookies = parseCookies();
      const result = await getCommunityTopic({cookies, version, topicId});
      if (!result.ok && result.error === InvalidTokenError) {
        updateIsSessionExpired(true);
      }

      return result;
    },
  });
};

export const followCommunityTopic = async ({
  cookies,
  version = DEFAULT_API_VERSION,
  topicId,
}: {
  cookies: Partial<Record<string, string>>;
  version?: string;
  topicId: number;
}): Promise<
  ResultWithOauthToken<UpdateFollowingCommunityTopicResponse, AppError>
> => {
  const request = async (accessToken: string) => {
    defaultClient.setToken(accessToken);
    return defaultClient.post(
      `/${version}/community-topics/${topicId}/like`,
      UpdateFollowingCommunityTopicResponseSchema,
      {},
    );
  };

  const result = await sendApiRequest({
    cookies,
    authRequired: true,
    request,
    version,
  });

  return result;
};

export const unFollowCommunityTopic = async ({
  cookies,
  version = DEFAULT_API_VERSION,
  topicId,
}: {
  cookies: Partial<Record<string, string>>;
  version?: string;
  topicId: number;
}): Promise<
  ResultWithOauthToken<UpdateFollowingCommunityTopicResponse, AppError>
> => {
  const request = async (accessToken: string) => {
    defaultClient.setToken(accessToken);
    return defaultClient.delete(
      `/${version}/community-topics/${topicId}/like`,
      UpdateFollowingCommunityTopicResponseSchema,
    );
  };

  const result = await sendApiRequest({
    cookies,
    authRequired: true,
    request,
    version,
  });

  return result;
};

type PostAlertCommunityTopicArgs = {
  cookies: Partial<Record<string, string>>;
  version?: string;
  topicId: number;
};

export const postAlertCommunityTopic = async ({
  cookies,
  version = DEFAULT_API_VERSION,
  topicId,
}: PostAlertCommunityTopicArgs): Promise<
  ResultWithOauthToken<PostAlertCommunityTopicResponse, AppError>
> => {
  const request = async (accessToken: string) => {
    defaultClient.setToken(accessToken);
    return defaultClient.post(
      `/${version}/community-topics/${topicId}/alert`,
      PostAlertCommunityTopicResponseScheme,
      {},
    );
  };

  const result = await sendApiRequest({
    cookies,
    authRequired: true,
    request,
    version,
  });

  return result;
};

type UsePostAlertCommunityTopicParams = Omit<
  PostAlertCommunityTopicArgs,
  'cookies'
> & {
  onMutateFnc?: () => void;
  onSuccessFnc: () => void;
  onErrorFnc: (error: AppError) => void;
  onSettledFnc?: () => void;
};

export const usePostAlertCommunityTopic = ({
  version = DEFAULT_API_VERSION,
  topicId,
  onMutateFnc,
  onSuccessFnc,
  onErrorFnc,
  onSettledFnc,
}: UsePostAlertCommunityTopicParams) => {
  const {updateIsSessionExpired} = useIsSessionExpired();

  return useMutation({
    async mutationFn() {
      const cookies = parseCookies();
      const result = await postAlertCommunityTopic({
        cookies,
        version,
        topicId,
      });
      if (!result.ok && result.error === InvalidTokenError) {
        updateIsSessionExpired(true);
      }

      return result;
    },
    onMutate() {
      if (onMutateFnc) {
        onMutateFnc();
      }
    },
    onSuccess() {
      onSuccessFnc();
    },
    onError(error: AppError) {
      onErrorFnc(error);
    },
    onSettled() {
      if (onSettledFnc) {
        onSettledFnc();
      }
    },
  });
};

type GetDoubtReasonListForCommunityTopicArgs = {
  cookies: Partial<Record<string, string>>;
  version?: string;
  topicId: number;
};

export const getDoubtReasonListForCommunityTopic = async ({
  cookies,
  version = DEFAULT_API_VERSION,
  topicId,
}: GetDoubtReasonListForCommunityTopicArgs): Promise<
  ResultWithOauthToken<GetCommunityDoubtReasonResponse, AppError>
> => {
  const request = async (accessToken: string) => {
    defaultClient.setToken(accessToken);
    return defaultClient.get(
      `/${version}/community-topics/${topicId}/doubt-reasons`,
      GetCommunityDoubtReasonResponseSchema,
    );
  };

  const result = await sendApiRequest({
    cookies,
    authRequired: true,
    request,
    version,
  });

  return result;
};

type UseGetDoubtReasonListForCommunityTopicParams = Omit<
  GetDoubtReasonListForCommunityTopicArgs,
  'cookies'
>;

export const useGetDoubtReasonListForCommunityTopic = ({
  version = DEFAULT_API_VERSION,
  topicId,
}: UseGetDoubtReasonListForCommunityTopicParams) => {
  const {updateIsSessionExpired} = useIsSessionExpired();

  return useQuery<
    ResultWithOauthToken<GetCommunityDoubtReasonResponse, AppError>
  >({
    queryKey: ['getDoubtReasonListForCommunityTopic', topicId],
    async queryFn() {
      const cookies = parseCookies();
      const result = await getDoubtReasonListForCommunityTopic({
        cookies,
        version,
        topicId,
      });
      if (!result.ok && result.error === InvalidTokenError) {
        updateIsSessionExpired(true);
      }

      return result;
    },
  });
};

type GetCommunityCommentsArgs = {
  cookies: Partial<Record<string, string>>;
  version?: string;
  topicId: number;
  cursor?: number;
  limit?: number;
  backward?: boolean;
  desc?: boolean;
  initial?: boolean;
  keyword?: string;
};

export const getCommunityComments = async ({
  cookies,
  version = DEFAULT_API_VERSION,
  topicId,
  cursor,
  limit,
  backward,
  desc,
  initial,
  keyword,
}: GetCommunityCommentsArgs): Promise<
  ResultWithOauthToken<GetCommunityCommentsResponse, AppError>
> => {
  const request = async (accessToken: string) => {
    defaultClient.setToken(accessToken);
    const params = filterExistParams({
      cursor,
      limit,
      backward,
      desc,
      initial,
      keyword,
    });
    const path = createPathWithQuery(
      `/${version}/community-topics/${topicId}/comments`,
      params,
    );
    return defaultClient.get(path, GetCommunityCommentsResponseSchema);
  };

  const result = await sendApiRequest({
    cookies,
    authRequired: true,
    request,
    version,
  });

  return result;
};

type UseGetCommunityCommentsParams = Omit<
  GetCommunityCommentsArgs,
  'cookies'
> & {
  config?: UseQueryOptions<
    ResultWithOauthToken<GetCommunityCommentsResponse, AppError>
  >;
};

export const useGetCommunityCommentList = ({
  version = DEFAULT_API_VERSION,
  topicId,
  cursor,
  limit,
  backward,
  desc,
  initial,
  keyword,
  config,
}: UseGetCommunityCommentsParams) => {
  const {updateIsSessionExpired} = useIsSessionExpired();

  return useQuery<ResultWithOauthToken<GetCommunityCommentsResponse, AppError>>(
    {
      ...config,
      queryKey: [
        'getCommunityCommentList',
        topicId,
        cursor,
        limit,
        backward,
        desc,
        initial,
        keyword,
      ],
      async queryFn() {
        const cookies = parseCookies();
        const result = await getCommunityComments({
          cookies,
          version,
          topicId,
          cursor,
          limit,
          backward,
          desc,
          initial,
          keyword,
        });
        if (!result.ok && result.error === InvalidTokenError) {
          updateIsSessionExpired(true);
        }

        return result;
      },
    },
  );
};

type PostCommunityCommentArgs = {
  cookies: Partial<Record<string, string>>;
  version?: string;
  topicId: number;
  nickname: string;
  body: string;
};

export const postCommunityComment = async ({
  cookies,
  version = DEFAULT_API_VERSION,
  topicId,
  nickname,
  body,
}: PostCommunityCommentArgs): Promise<
  ResultWithOauthToken<PostCommunityCommentResponse, AppError>
> => {
  const request = async (accessToken: string) => {
    defaultClient.setToken(accessToken);
    return defaultClient.post(
      `/${version}/community-topics/${topicId}/comments`,
      PostCommunityCommentResponseSchema,
      {nickname, body},
    );
  };

  const result = await sendApiRequest({
    cookies,
    authRequired: true,
    request,
    version,
  });

  return result;
};

type UsePostCommunityCommentParams = {
  version?: string;
  onMutateFnc?: () => void;
  onSuccessFnc: () => void;
  onErrorFnc: (error: AppError) => void;
  onSettledFnc?: () => void;
};

export const usePostCommunityComment = ({
  version = DEFAULT_API_VERSION,
  onMutateFnc,
  onSuccessFnc,
  onErrorFnc,
  onSettledFnc,
}: UsePostCommunityCommentParams) => {
  const {updateIsSessionExpired} = useIsSessionExpired();

  return useMutation({
    async mutationFn({
      topicId,
      nickname,
      body,
    }: Omit<PostCommunityCommentArgs, 'cookies'>) {
      const cookies = parseCookies();
      const result = await postCommunityComment({
        cookies,
        version,
        topicId,
        nickname,
        body,
      });
      if (!result.ok && result.error === InvalidTokenError) {
        updateIsSessionExpired(true);
      }

      return result;
    },
    onMutate() {
      if (onMutateFnc) {
        onMutateFnc();
      }
    },
    onSuccess() {
      onSuccessFnc();
    },
    onError(error: AppError) {
      onErrorFnc(error);
    },
    onSettled() {
      if (onSettledFnc) {
        onSettledFnc();
      }
    },
  });
};

type GetCommunityCommentArgs = {
  cookies: Partial<Record<string, string>>;
  version?: string;
  topicId: number;
  seqNumber: number;
};

export const getCommunityComment = async ({
  cookies,
  version = DEFAULT_API_VERSION,
  topicId,
  seqNumber,
}: GetCommunityCommentArgs): Promise<
  ResultWithOauthToken<GetCommunityCommentResponse, AppError>
> => {
  const request = async (accessToken: string) => {
    defaultClient.setToken(accessToken);
    return defaultClient.get(
      `/${version}/community-topics/${topicId}/comments/${seqNumber}`,
      GetCommunityCommentResponseSchema,
    );
  };

  const result = await sendApiRequest({
    cookies,
    authRequired: true,
    request,
    version,
  });

  return result;
};

type UseGetCommunityCommentParams = Omit<GetCommunityCommentArgs, 'cookies'> & {
  config?: UseQueryOptions<
    ResultWithOauthToken<GetCommunityCommentResponse, AppError>
  >;
};

export const useGetCommunityComment = ({
  version = DEFAULT_API_VERSION,
  topicId,
  seqNumber,
  config,
}: UseGetCommunityCommentParams) => {
  const {updateIsSessionExpired} = useIsSessionExpired();

  return useQuery<ResultWithOauthToken<GetCommunityCommentResponse, AppError>>({
    ...config,
    queryKey: ['getCommunityComment', topicId, seqNumber],
    async queryFn() {
      const cookies = parseCookies();
      const result = await getCommunityComment({
        cookies,
        version,
        topicId,
        seqNumber,
      });
      if (!result.ok && result.error === InvalidTokenError) {
        updateIsSessionExpired(true);
      }

      return result;
    },
  });
};

type UpdateLikedCommunityCommentArgs = {
  cookies: Partial<Record<string, string>>;
  version?: string;
  topicId: number;
  seqNumber: number;
};

export const postLikedCommunityComment = async ({
  cookies,
  version = DEFAULT_API_VERSION,
  topicId,
  seqNumber,
}: UpdateLikedCommunityCommentArgs): Promise<
  ResultWithOauthToken<UpdateLikedCommunityCommentResponse, AppError>
> => {
  const request = async (accessToken: string) => {
    defaultClient.setToken(accessToken);
    return defaultClient.post(
      `/${version}/community-topics/${topicId}/comments/${seqNumber}/like`,
      UpdateLikedCommunityCommentResponseSchema,
      {},
    );
  };

  const result = await sendApiRequest({
    cookies,
    authRequired: true,
    request,
    version,
  });

  return result;
};

export const deleteLikedCommunityComment = async ({
  cookies,
  version = DEFAULT_API_VERSION,
  topicId,
  seqNumber,
}: UpdateLikedCommunityCommentArgs): Promise<
  ResultWithOauthToken<UpdateLikedCommunityCommentResponse, AppError>
> => {
  const request = async (accessToken: string) => {
    defaultClient.setToken(accessToken);
    return defaultClient.delete(
      `/${version}/community-topics/${topicId}/comments/${seqNumber}/like`,
      UpdateLikedCommunityCommentResponseSchema,
    );
  };

  const result = await sendApiRequest({
    cookies,
    authRequired: true,
    request,
    version,
  });

  return result;
};

type PostAlertCommunityCommentArgs = {
  cookies: Partial<Record<string, string>>;
  version?: string;
  topicId: number;
  seqNumber: number;
};

export const postAlertCommunityComment = async ({
  cookies,
  version = DEFAULT_API_VERSION,
  topicId,
  seqNumber,
}: PostAlertCommunityCommentArgs): Promise<
  ResultWithOauthToken<PostAlertCommunityCommentResponse, AppError>
> => {
  const request = async (accessToken: string) => {
    defaultClient.setToken(accessToken);
    return defaultClient.post(
      `/${version}/community-topics/${topicId}/comments/${seqNumber}/alert`,
      PostAlertCommunityCommentResponseSchema,
      {},
    );
  };

  const result = await sendApiRequest({
    cookies,
    authRequired: true,
    request,
    version,
  });

  return result;
};

type UsePostAlertCommunityCommentParams = Omit<
  PostAlertCommunityCommentArgs,
  'cookies'
> & {
  onMutateFnc?: () => void;
  onSuccessFnc: () => void;
  onErrorFnc: (error: AppError) => void;
  onSettledFnc?: () => void;
};

export const usePostAlertCommunityComment = ({
  version = DEFAULT_API_VERSION,
  topicId,
  seqNumber,
  onMutateFnc,
  onSuccessFnc,
  onErrorFnc,
  onSettledFnc,
}: UsePostAlertCommunityCommentParams) => {
  const {updateIsSessionExpired} = useIsSessionExpired();

  return useMutation({
    async mutationFn() {
      const cookies = parseCookies();
      const result = await postAlertCommunityComment({
        cookies,
        version,
        topicId,
        seqNumber,
      });
      if (!result.ok && result.error === InvalidTokenError) {
        updateIsSessionExpired(true);
      }

      return result;
    },
    onMutate() {
      if (onMutateFnc) {
        onMutateFnc();
      }
    },
    onSuccess() {
      onSuccessFnc();
    },
    onError(error: AppError) {
      onErrorFnc(error);
    },
    onSettled() {
      if (onSettledFnc) {
        onSettledFnc();
      }
    },
  });
};

type PostDoubtCommunityCommentArgs = {
  cookies: Partial<Record<string, string>>;
  version?: string;
  topicId: number;
  seqNumber: number;
  doubtReasonId: number;
  detail: string;
};

export const postDoubtCommunityComment = async ({
  cookies,
  version = DEFAULT_API_VERSION,
  topicId,
  seqNumber,
  doubtReasonId,
  detail,
}: PostDoubtCommunityCommentArgs): Promise<
  ResultWithOauthToken<PostDoubtCommunityCommentResponse, AppError>
> => {
  const request = async (accessToken: string) => {
    defaultClient.setToken(accessToken);
    return defaultClient.post(
      `/${version}/community-topics/${topicId}/comments/${seqNumber}/doubt`,
      PostDoubtCommunityCommentResponseSchema,
      {doubtReasonId, detail},
    );
  };

  const result = await sendApiRequest({
    cookies,
    authRequired: true,
    request,
    version,
  });

  return result;
};

type UsePostDoubtCommunityCommentParams = Omit<
  PostDoubtCommunityCommentArgs,
  'doubtReasonId' | 'detail' | 'cookies'
> & {
  onMutateFnc?: () => void;
  onSuccessFnc: () => void;
  onErrorFnc: (error: AppError) => void;
  onSettledFnc?: () => void;
};

export const usePostDoubtCommunityComment = ({
  version = DEFAULT_API_VERSION,
  topicId,
  seqNumber,
  onMutateFnc,
  onSuccessFnc,
  onErrorFnc,
  onSettledFnc,
}: UsePostDoubtCommunityCommentParams) => {
  const {updateIsSessionExpired} = useIsSessionExpired();

  return useMutation({
    async mutationFn({
      doubtReasonId,
      detail,
    }: {
      doubtReasonId: number;
      detail: string;
    }) {
      const cookies = parseCookies();
      const result = await postDoubtCommunityComment({
        cookies,
        version,
        topicId,
        seqNumber,
        doubtReasonId,
        detail,
      });
      if (!result.ok && result.error === InvalidTokenError) {
        updateIsSessionExpired(true);
      }

      return result;
    },
    onMutate() {
      if (onMutateFnc) {
        onMutateFnc();
      }
    },
    onSuccess() {
      onSuccessFnc();
    },
    onError(error: AppError) {
      onErrorFnc(error);
    },
    onSettled() {
      if (onSettledFnc) {
        onSettledFnc();
      }
    },
  });
};

type GetCommunityTagListArgs = {
  cookies: Partial<Record<string, string>>;
  version?: string;
  isPopular: boolean;
};

export const getCommunityTagList = async ({
  cookies,
  version = DEFAULT_API_VERSION,
  isPopular = false,
}: GetCommunityTagListArgs): Promise<
  ResultWithOauthToken<GetCommunityTagListResponseType, AppError>
> => {
  const request = async (accessToken: string) => {
    defaultClient.setToken(accessToken);
    const path = createPathWithQuery(`/${version}/community-tags`, {
      isPopular,
    });
    return defaultClient.get(path, GetCommunityTagListResponseScheme);
  };

  const result = await sendApiRequest({
    cookies,
    authRequired: true,
    request,
    version,
  });

  return result;
};

type UseGetCommunityTagListParams = Omit<GetCommunityTagListArgs, 'cookies'> & {
  config?: UseQueryOptions<
    ResultWithOauthToken<GetCommunityTagListResponseType, AppError>
  >;
};

export const useGetCommunityTagList = ({
  version = DEFAULT_API_VERSION,
  isPopular,
  config,
}: UseGetCommunityTagListParams) => {
  const {updateIsSessionExpired} = useIsSessionExpired();

  return useQuery<
    ResultWithOauthToken<GetCommunityTagListResponseType, AppError>
  >({
    ...config,
    queryKey: ['getCommunityTagList', isPopular],
    async queryFn() {
      const cookies = parseCookies();
      const result = await getCommunityTagList({cookies, version, isPopular});
      if (!result.ok && result.error === InvalidTokenError) {
        updateIsSessionExpired(true);
      }

      return result;
    },
  });
};
